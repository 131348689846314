<template>
<div class="bg bg—start">
  <header-block cls="header__menu--start"/>
  <div class="container">
    <div class="start">
      <h1 class="title">
        Тест «Любовь<br>к себе»
      </h1>
      <p class="txt txt--start">
        Любовь к себе дарит уверенность и гармонию во всех сферах жизни.
        Наш тест поможет узнать, насколько вы любите и цените себя.
      </p>
      <router-link to="/test/self" class="button">
        Начать
      </router-link>
      <img src="@/assets/images/start.png"
        srcset="@/assets/images/start@2x.png 2x" alt="img" class="start__img">
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'start-couple',
};
</script>
